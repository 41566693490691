import React from "react";
import QRCode from "react-qr-code";
import { SiPhonepe } from "react-icons/si";
import g_pay_icon from "../../assets/image/g_pay_icon.png";
import { Checkbox } from "antd";
import paytm from "../../assets/image/paytm.png";
import g_id from "../../assets/image/g_id.jpg";
import p_id from "../../assets/image/p_id.jpg";
import pp_id from "../../assets/image/p_p_id.jpg";
import { useRef } from "react";
import { getUPI } from "../../lib/api/upi";
import { useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useEffect } from "react";
import { postCredit } from "../../lib/api/credit";
import { useHistory } from "react-router-dom";
import NumberFormat from "../../utilities/NumberFormat";

function Payment({ userDetail }) {
  const { authToken, userId } = useAuth();
  const history = useHistory();

  const [upiId, setUPIID] = useState("");
  const [error, setError] = useState("");
  const [checkBox, setCheckBox] = useState("");
  const [transactionId, setTransactionId] = useState("");

  function onChange(e) {
    setCheckBox(e.target.checked);
  }

  const getUPIS = () => {
    getUPI(authToken)
      .then((res) => {
        setUPIID(res[0]?.upi);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getUPIS();
  }, []);

  const onHandleAddAmount = () => {
    if (transactionId === "") {
      setError("Enter transaction Id . . !");
    } else if (amount === 0 || amount < 100) {
      setError("Minimum amount 100 . . !");
    } else if (!checkBox) {
      setError("Agree to continue . . !");
    } else {
      let data = {
        amount: parseInt(amount),
        upiId: upiId,
        transactionId: transactionId,
        userId: userId
      };
      insertCredit(data);
      setError("");
      setCheckBox(false);
      setTransactionId("");
      history.replace(`/`);
    }
  };

  const insertCredit = async (data) => {
    await postCredit(authToken, data);
  };

  const textRef = useRef(null);

  const location = window.location;
  const queryParams = new URLSearchParams(location.search);
  const amount = queryParams.get("amount");

  const handleCopyClick = () => {
    if (textRef.current) {
      const textArea = document.createElement("textarea");
      textArea.value = upiId;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      alert(`${upiId} UPI copied!`);
    }
  };
  //business.darkdice@okhdfcbank
  return (
    <div className="space-y-3">
      <p className="pt-2 font-medium pl-2 text-gray-900 text-lg">
        Coeee Payment
      </p>
      <div className="mx-3 bg-green-200">
        <div className="bg-green-800 text-white font-medium py-1 pl-3 ">
          QR Code / UPI Id
        </div>
        <div className="p-3  space-x-2 items-center justify-between">
          <div className="items-center flex justify-center">
            <div className="bg-white p-1">
              <QRCode
                value={`upi://pay?pa=${upiId}&pn=DarkDice&am=${amount}.00&cu=INR&aid=uGICAgMD2jtTOIw`}
                size={120}
              />
            </div>
          </div>
          <p className="text-center font-black">OR</p>
          <div>
            <div className="font-medium text-black mt-1 text-center">
              {upiId}
            </div>

            <button
              className="w-full text-center py-1 rounded-md
                active:bg-blue-400 bg-blue-600 font-bold
                text-gray-50 hover:bg-blue-400
                  focus:outline-none my-1"
              id="open-btn"
              onClick={() => handleCopyClick()}
            >
              <span ref={textRef} className="hidden">
                {upiId}
              </span>
              <span>Tap to copy - UPI</span>
            </button>
          </div>
        </div>
        <div className="mx-3">
          <div className="font-medium text-black">
            Amount = {NumberFormat.currencyFormat(parseInt(amount))}
          </div>
          <div className="text-black mt-2">
            Using scan the QRcode Or copy UPI Pay the amount
          </div>
          <div className="flex items-center space-x-2">
            <div className="flex items-center">
              <img className="h-12 w-12" src={g_pay_icon} alt="" />
              <p className="font-medium -ml-2">GPay</p>
            </div>
            <img className="h-12 w-16 rounded-full" src={paytm} alt="" />{" "}
            <SiPhonepe size={20} color={"#6739B7"} />
          </div>
        </div>
      </div>

      <p className="ml-3 font-medium text-lg text-black">
        Reference Screen Shot's
      </p>
      <div className="border-2 space-y-1 mx-2">
        <p className="ml-3 font-medium text-lg text-black">Google Pay</p>
        <img src={g_id} alt="" />
        <p className="ml-3 font-medium text-lg text-black">Phone Pay</p>
        <img src={pp_id} alt="" />
        <p className="ml-3 font-medium text-lg text-black">Paytm Pay</p>
        <img src={p_id} alt="" />
      </div>

      <div className="mx-3">
        <input
          type={"text"}
          className={`font-medium 
                h-10 w-full p-3 rounded-md 
                ${
                  error === "Enter transaction Id . . !"
                    ? "bg-red-100 text-red-600 border-2 border-red-300 focus:outline-red-300 placeholder:text-red-600"
                    : "bg-gray-200 text-gray-600 border-2 focus:outline-gray-700 border-b-gray-500"
                }`}
          name="transactionId"
          value={transactionId}
          placeholder="Enter transaction Id"
          onChange={(e) => setTransactionId(e.target.value)}
        />
        {error === "Enter transaction Id . . !" ? (
          <p className="text-sm text-red-600 mt-1">{error}</p>
        ) : null}
      </div>

      <div
        className="flex ml-3 my-4 font-medium"
        style={{
          color: error === "Agree to continue . . !" ? "red" : "black"
        }}
      >
        <Checkbox checked={checkBox ? true : false} onChange={onChange}>
          Agree to add the amount
        </Checkbox>
      </div>
      <div>
        {error === "Minimum amount 100 . . !" ? (
          <p className="text-sm ml-3 text-red-600 m-b-2">{error}</p>
        ) : null}
        <button
          className="w-36 ml-3 text-center py-2 rounded-md
                           active:bg-green-700 bg-green-500 font-bold
                          text-white hover:bg-green-700
                           focus:outline-none my-1"
          id="open-btn"
          onClick={() => onHandleAddAmount()}
        >
          Add Amount
        </button>
        <button
          className="w-56 ml-3 text-center py-2 rounded-md
         active:bg-red-700 bg-red-500 font-bold
        text-white hover:bg-red-700
         focus:outline-none my-1"
          id="open-btn"
          onClick={() => {
            const email = "noreply.multigameserver@gmail.com";
            const subject = "Add Amount Issue";
            const body = `We are having a issue with adding amount!`;
            const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
              subject
            )}&body=${encodeURIComponent(body)}`;
            window.location = mailtoLink;
          }}
        >
          {`Any issues -> sent an email`}
        </button>
      </div>
      <p className="pb-3 font-medium"> </p>
    </div>
  );
}

export default Payment;
